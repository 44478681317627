/* eslint-disable max-len */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Cookies | Geo Partner firma geodezyjna Olsztyn</title>
    <meta
      name="description"
      content="Polityka plików cookies i stosowane technologie internetowe - Geodezja Geo Partner"
    />
    <link rel="canonical" href="https://geo-partner.pl/cookies/" />
  </>
);

const Main = styled.main`
  /* font-family: ${({ theme }) => theme.font.family.sans}; */

  p {
    line-height: 150%;
  }

  .subtitle {
    margin: 0;
    text-transform: uppercase;
  }

  .btn {
    svg {
      width: 30px;
      vertical-align: middle;
      margin: 0 6px 0 -10px;
    }
  }
`;

const Slider = styled.div`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const TermsList = styled.ol`
  counter-reset: lvl_1;
  line-height: 150%;
  list-style: none;
  font-size: 1.6rem;
  margin: 0 0 7rem 4rem;
  max-width: 975px;
  padding: 0;

  > li {
    counter-increment: lvl_1;

    h3 {
      position: relative;

      &::before {
        content: counter(lvl_1) ".";
        left: -25px;
        position: absolute;
      }
    }

    p {
      margin-bottom: 1rem;
    }

    > ol {
      counter-reset: lvl_2;
      list-style: none;
      padding: 0;

      @media only screen and (max-width: 480px) {
        margin-left: -38px;
      }

      > li {
        padding: 0 0 1rem 4rem;
        position: relative;

        &::before {
          counter-increment: lvl_2;
          content: counter(lvl_1) "." counter(lvl_2);
          font-weight: 600;
          left: 0;
          position: absolute;
          text-align: right;
          top: 0;
        }
      }
    }
  }

  h3 {
    color: inherit;
    text-transform: uppercase;
  }

  ul {
    margin: 2rem 0 2rem 3rem;
    padding: 0;

    li {
      margin: 0 0 1rem;
    }
  }
`;

const Contact = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_3)}
        alt="Geodeta Olsztyn"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1 style={{ marginBottom: '8px' }}>
          Cookies
        </h1>

        <h2 style={{
          fontSize: '1.7rem',
          paddingTop: '0',
          textTransform: 'uppercase',
        }}
        >Polityka plików cookies i&nbsp;stosowane technologie internetowe
        </h2>

        <p style={{ maxWidth: '1040px' }}>
          Niniejsza <em>Polityka Cookies</em>, dotyczy przetwarzania danych osobowych z użyciem plików <em>cookies</em> i stosowania innych technologii internetowych oraz przetwarzania danych osobowych za ich pomocą, które stosowane są na stronie internetowej
          {' '}
          <Link to="/">geo-partner.pl</Link>
          .
        </p>

        <TermsList>
          <li>
            <h3>Cookies</h3>

            <ol>
              <li>
                <p>Pliki cookies (tzw. <em>"ciasteczka"</em>) stanowią dane informatyczne, w&nbsp;szczególności pliki tekstowe, które przechowywane są w&nbsp;urządzeniu końcowym Użytkownika Serwisu (np. komputerze, tablecie, smartphonie) i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies nie są szkodliwe dla Użytkowników czy ich urządzeń, nie wpływają niekorzystnie na ich działanie. Nie powodują też zmian konfiguracyjnych w&nbsp;urządzeniach końcowych ani w&nbsp;oprogramowaniu zainstalowanym na tych urządzeniach.</p>
              </li>

              <li>
                <p>W ramach Serwisu stosowany jest jeden rodzaj plików cookies: <em>"trwałe pliki cookies"</em> (persistent cookies). Pliki cookies przechowywane są w&nbsp;urządzeniu końcowym Użytkownika, przez czas określony w&nbsp;parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</p>
              </li>

              <li>
                <p>Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w&nbsp;urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w&nbsp;tym zakresie, ponieważ przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies. Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja używanej przeglądarki internetowej.</p>
              </li>

              <li>
                <p>W ramach Serwisu mogą być stosowane m.in.:</p>

                <ul>
                  <li><em>"wydajnościowe"</em> - umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych;</li>
                  <li><em>"funkcjonalne"</em> - umożliwiające "zapamiętanie" wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w&nbsp;zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;</li>
                </ul>
              </li>
              <li>
                <p>W zależności od używanej strony internetowej lub aplikacji mobilnej, rodzaj oraz ilość plików cookies może się różnić.</p>
              </li>
            </ol>
          </li>
          <li>
            <h3>Zakres zbieranych danych</h3>

            <ol>
              <li>
                <p>Cookies identyfikują dane urządzenia i przeglądarki używane do przeglądania stron internetowych - pozwalają np. dowiedzieć się czy dane urządzenie odwiedzało już stronę. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</p>
              </li>
              <li>
                <p>W ramach Serwisu Administrator może przetwarzać dane dotyczące sposobu korzystania z witryny za pomocą komputera, tabletu czy smartfona lub innego urządzenia, za pomocą którego Użytkownik uzyskuje dostęp do stron internetowych. Niektóre z tych danych, w&nbsp;połączeniu z innymi danymi, mogą stanowić dane osobowe.</p>
              </li>
              <li>
                <p>Za pomocą stosowanych technologii internetowych Administrator może przetwarzać np.:</p>
                <ul>
                  <li>dane urządzenia: typ/model urządzenia, unikalne identyfikatory urządzeń, adres MAC, adres IP, system operacyjny, wersja systemu operacyjnego i ustawienia urządzenia, ustawiony język, rozdzielczość ekranu, wersja i rodzaj przeglądarki internetowej;</li>
                  <li>dziennik / zdarzenia: czas korzystania z Serwisu, dane wyszukiwania oraz wszelkie informacje przechowywane w plikach cookies, które jednoznacznie identyfikują przeglądarkę lub konto Użytkownika;</li>
                  <li>dane dotyczące lokalizacji: adres IP, dane o lokalizacji Użytkownika uzyskane za pomocą różnych technologii pozycjonowania - (długość i szerokość geograficzna, ISP np. Play/Orange, inne podane przez Użytkownika dane lokalizacyjne), takich jak GPS, punkty dostępu Wi-Fi lub inne czujniki, które mogą dostarczać dane w pobliżu urządzenia (po wyrażeniu zgody na lokalizację);</li>
                  <li>inne dane na temat korzystania z Serwisu, które mogą być przetwarzane, jeśli Użytkownik odwiedza lub korzysta ze stron internetowych Administratora, np. źródło wejścia pod UTM, kampania reklamowa, z której Użytkownik wszedł na stronę, referrale, linki, w które kliknął Użytkownik, ilość kliknięć i widoków dynamicznej zawartości strony, jakie reklamy zobaczył Użytkownik oraz gdzie je zobaczył.</li>
                </ul>
              </li>

              <li>W związku z dostępem do Serwisu Administratora dane z urządzenia końcowego przetwarzane są w sposób zautomatyzowany w związku z jego wywołaniem lub wykonywaniem aktywności na stronie, dzięki czemu Administrator ocenia niektóre czynniki dotyczące osób fizycznych, aby poprzez analizę ich zachowania przewidywać przyszłe zachowanie, jednakże bez podejmowania istotnych decyzji dotyczących Użytkownika.</li>
            </ol>
          </li>

          <li>
            <h3>Cele i podstawa prawna stosowanych technologii internetowych</h3>

            <ol>
              <li>
                <p>Administrator wykorzystuje stosowane technologie internetowe do:</p>

                <ul>
                  <li>zapewnienia funkcjonowania, bezpieczeństwa i niezawodności Serwisu;</li>
                  <li>dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych, ulepszania własnej strony internetowej (odpowiednie wyświetlanie strony internetowej, dostosowanie jej do indywidualnych potrzeb Użytkownika);</li>
                  <li>analizy i tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;</li>
                  <li>w celach reklamowych (w tym profilowania, remarketingu, retargetingu) i łączenia się z mediami społecznościami, wyświetlania reklamy behawioralnej (opartej na zainteresowaniach) mającej dostarczać Użytkownikom treści reklamowej dostosowanej do ich zainteresowań lub profili. Mogą być używane również do wyświetlania reklam poza stronami Serwisu (także z wykorzystaniem tzw. third party cookies) partnerów reklamowych Administratora. Celem Administratora jest opracowanie oferty reklamowej, która będzie atrakcyjna i korzystna dla Użytkownika, dlatego w ramach reklamy behawioralnej Użytkownik może zobaczyć przede wszystkim informacje o produktach lub usługach, które oglądał lub sprawdzał już na jego stronach.</li>
                </ul>
              </li>
              <li>Korzystanie z plików cookies lub podobnych technologii na stronach internetowych Administratora odbywa się za zgodą Użytkownika wyrażoną poprzez korzystanie ze strony bez zmiany ustawień przeglądarki w celach analitycznych, statystycznych, dostosowywania strony do indywidualnych potrzeb Użytkowników oraz w celach reklamowych (tj. pozostanie na stronie internetowej). W ten sposób Użytkownik zgadza się na to, iż cookies lub inne podobne technologie będą umieszczane w jego urządzeniu końcowym (komputerze, tablecie, smartfonie) oraz na używanie informacji zapisanych w plikach cookies przez Administratora.</li>
              <li>W każdej chwili Użytkownik może cofnąć swoją zgodę lub wyrazić swój sprzeciw na przetwarzanie jego danych osobowych zmieniając ustawienia plików cookies poprzez zmianę ustawień przeglądarki lub skorzystanie z innych sposobów pozwalających na zmianę ustawień cookies.</li>
              <li>Domyślnym ustawieniem większości wyszukiwarek jest akceptacja wszystkich plików cookies. Można jednak zmienić ustawienia w wyszukiwarce, tak by żądała akceptacji plików cookies, by akceptowała lub odrzucała tylko niektóre rodzaje plików cookies, bądź też odrzucała wszystkie z nich.</li>
              <li>Należy pamiętać, że niektóre pliki cookies są niezbędne do funkcjonowania stron internetowych Administratora, a ich usunięcie lub wyłączenie może obniżyć ich funkcjonalność.</li>
              <li>Cookies lub inne technologie wykorzystywane są automatycznie, co nie oznacza, że na stronach internetowych Administratora wykorzystywane są z zamiarem automatycznego podejmowania decyzji lub w celu wywołania podobnego skutku prawnego, mogącego godzić w interesy lub prywatność Użytkowników.</li>
            </ol>
          </li>

          <li>
            <h3>Funkcjonalności lub technologie dostawców zewnętrznych</h3>

            <ol>
              <li>Administrator na swoich stronach korzysta z usług i narzędzi dostawców zewnętrznych, w szczególności w zakresie związanym z dostarczaniem usług dostępnych za pośrednictwem strony (np. usługi czat, callpage) oraz z analityką, reklamą behawioralną (opartą na zainteresowaniach), remarketingiem, pomiarem rezultatów kampanii reklamowych.</li>
              <li>Na stronach internetowych Administratora lub fanpage'ach mogą być używane wtyczki sieci społecznościowych podmiotów trzecich, np. Facebook, (pluginy lub tzw. <em>"wtyczki"</em> np. <em>"Like"</em> <em>"Udostępnij"</em>), oznaczone za pomocą powszechnie znanych ikon tych sieci. W tym celu na odpowiednich stronach umieszczany jest kod odwołujący się do powyższych sieci. Treść ze stron internetowych Administratora może zostać przesłana na tę stronę lub do tej usługi. W zależności od ustawień prywatności Użytkownika, może ona być widoczna publicznie lub prywatnie (np. tylko dla znajomych, śledzących lub dla każdego, kto wejdzie na profil Użytkownika). Za pomocą tych wtyczek Użytkownicy mogą jako zalogowany użytkownik tych serwisów udostępniać w ramach tych serwisów stronę, na której się znajdują. Wtyczka zostanie jednak załadowana dopiero wówczas, jeżeli Użytkownicy zainstalują odpowiednią wtyczkę za pomocą ikony aktywacyjnej. Zawartość wtyczki będzie wówczas przekazywana przez odpowiedni serwis społecznościowy bezpośrednio na urządzenie końcowe Użytkownika i będzie tam wyświetlana. W przypadku zaznaczenia odpowiedniej opcji wyboru, aktywacja ta będzie obowiązywała na stałe podczas odwiedzin Użytkownika w Serwisie internetowym Administratora. Użytkownicy w każdej chwili mogą dezaktywować przyciski, klikając na <em>"Cofnij"</em>. Bez odpowiedniej aktywacji wtyczki pozostaną nieaktywne i nie zostanie utworzone połączenie z serwisami społecznościowymi. Jeżeli Użytkownicy aktywowali używanie wtyczki, wówczas przetwarzane są następujące dane i przekazywane do dostawcy wtyczki: (data i godzina dostępu, odwiedzona część serwisu internetowego, adres IP, nazwa domeny).</li>
              <li>Na stronach internetowych Administratora znajdują się także zewnętrzne linki, np. do jego strony na Facebooku. Administrator nie ma kontroli nad tym, jakie dane gromadzi dostawca wtyczek lub stron serwisów społecznościowych i jak je przetwarza. Aby uzyskać informacje na temat celu i zakresu gromadzenia danych, w tym także plików cookies tam stosowanych, ich dalszego przetwarzania i wykorzystywania przez zewnętrznych dostawców oraz przysługujących Użytkowników praw oraz opcji ustawień ochrony prywatności tych dostawców, Użytkownik może zapoznać się z informacją o ochronie danych odpowiedniego dostawcy.</li>
            </ol>
          </li>

          <li>
            <h3>Przetwarzanie danych poza EOG</h3>

            <p>W związku z możliwością udostępniania danych zbieranych przy pomocy cookies dostawców zewnętrznych Administratora, informuje on, iż niektóre dane mogą być przekazane poza teren Europejskiego Obszaru Gospodarczego. Takie przekazywanie danych może odbywać się na podstawie standardowych klauzul umownych zgodnie z decyzją Komisji Europejskiej albo na podstawie wyraźnej zgody Użytkownika.</p>
          </li>

          <li>
            <h3>Cofnięcie zgody na pliki cookies</h3>

            <ul>
              <li>Pliki cookies mogą być przez Użytkownika usunięte po ich zapisaniu przez Administratora, poprzez odpowiednie funkcje przeglądarki internetowej lub inne narzędzia służące do tego celu.</li>
              <li>Użytkownicy Serwisu mogą cofnąć zgodę i dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).</li>
              <li>W wyniku zmiany ustawień w przeglądarce Użytkownika zostanie umieszczony tzw. plik cookie opt - out. Służy on wyłącznie do identyfikacji sprzeciwu Użytkownika - braku zgody. Należy pamiętać, iż plik cookie - opt out działa tylko w przeglądarce, przy użyciu której został zapisany. Jeśli usunięte zostaną wszystkie cookies lub Użytkownik będzie używać innej przeglądarki bądź innego urządzenia końcowego, to ustawień pliku opt- out trzeba dokonać ponownie.</li>
            </ul>
          </li>

          <li>
            <h3>Postanowienia końcowe</h3>

            <ol>
              <li>Można zmienić ustawienia w przeglądarce, tak by żądała akceptacji plików cookies, by akceptowała lub odrzucała tylko niektóre rodzaje plików cookies, bądź też odrzucała wszystkie z nich. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej - w opisie funkcji pomocy przeglądarki lub może skontaktować się z producentem oprogramowania odnośnie do instrukcji dotyczących wyłączania i usuwania plików cookies).</li>
              <li>Użytkownik może wyłączyć poszczególnych reklamodawców korzystających z historii wyszukiwania w celu dostarczania internetowych reklam behawioralnych, odwiedzając stronę <a href="http://www.youronlinechoices.com/pl/twojewybory" rel="nofollow">http://www.youronlinechoices.com/pl/twojewybory</a>.</li>
            </ol>
          </li>
        </TermsList>

      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_3: file(relativePath: {regex: "/hero\/3.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
  }
`;

export default Contact;
